window.onload = () => {
    const scrollLinks = document.getElementsByClassName('scrollLink');
    const collapse = document.getElementById('navbarmenu');
    for (const sl of scrollLinks) {
        if (sl.tagName === 'A') {
            const href = sl.getAttribute('href');
            if (href[0] === '#') {
                const targetId = href.slice(1);
                const target = document.getElementById(targetId);
                if (target) {
                    sl.addEventListener('click', (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const collapseMenu = bootstrap.Collapse.getInstance(collapse);
                        if (collapseMenu) {
                            collapseMenu.hide();
                        }
                        target.scrollIntoView({ block: 'start', behavior: 'smooth' });
                    });
                }
            }
        }
    }
    const getStartedContainer = document.getElementById('getStartedFormContainer');
    const getStartedForm = document.getElementById('getStartedForm');
    const getStartedButton = document.getElementById('getStartedButton');
    getStartedButton.addEventListener('click', () => {
        if (getStartedForm.checkValidity()) {
            getStartedContainer.classList.add('submitted');
            const firstName = document.getElementById('firstName').value;
            const lastName = document.getElementById('lastName').value;
            const phone = document.getElementById('phone').value;
            const email = document.getElementById('email').value;
            const body = { firstName, lastName, phone, email };
            fetch('/api/GetStarted/Register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                  },
                body: JSON.stringify(body)
            });
        } else {
            getStartedForm.classList.add('was-validated');
        }
    });
}
